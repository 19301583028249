@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap");
@font-face {
  font-family: "FolitoBlack";
  src: url("../assets//fonts/Folito-Black.ttf") format("truetype");
}
@font-face {
  font-family: "FolitoBold";
  src: url("../assets//fonts/Folito-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "FolitoRegular";
  src: url("../assets//fonts/Folito-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "FolitoMedium";
  src: url("../assets//fonts/Folito-Medium.ttf") format("truetype");
}
// Bootstrap mixins and functions
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";

// Change variables here
@import "kerativ/variables";

//themes
@import "kerativ/themes";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

// pages
@import "kerativ/pages";

// Vendor
@import "kerativ/vendor";

// kerativ mixins & functions
@import "kerativ/mixins";
@import "kerativ/functions";

// Utilities
@import "kerativ/reboot";
@import "kerativ/utilities";

// Layout
@import "kerativ/layout";

// Components
@import "kerativ/components";

// write your custom styles here!
