.store-profile {
  .password-wrapper {
    position: relative;
    .eye-icon {
      cursor: pointer;
      position: absolute;
      right: 1rem;
      top: 2.4rem;
      padding: 5px;
    }
  }
}
