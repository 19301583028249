input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  // background-color: white !important;
  border: $lightGrey;
  -webkit-text-fill-color: $black;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: $primary !important;
}
.alice-carousel__dots {
  // margin: 0 !important;
}
.ripple-section {
  padding-top: 5%;
  padding-bottom: 5%;
  position: relative;

  @keyframes ripple1 {
    0% {
      transform: scale(5.5);
      opacity: 0.3;
    }
    100% {
      transform: scale(8.5);
      opacity: 0;
    }
  }
  @keyframes ripple2 {
    0% {
      transform: scale(3.5);
      opacity: 0.3;
    }
    100% {
      transform: scale(5.5);
      opacity: 0;
    }
  }
  @keyframes ripple3 {
    0% {
      transform: scale(1.5);
      opacity: 0.3;
    }
    100% {
      transform: scale(3.5);
      opacity: 0;
    }
  }

  .animation-circle-inverse i {
    top: 44%;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: #eee;
    // box-shadow: 0 15px 30px 0 rgb(0 0 0 / 11%);
    position: absolute;
    height: 100px;
    width: 100px;
    border-radius: 100%;
    opacity: 0.3;
    transform: scale(1.3);
    animation: ripple1 3s linear infinite;
  }
  .animation-circle-inverse i:nth-child(2) {
    animation: ripple2 3s linear infinite;
  }
  .animation-circle-inverse i:nth-child(3) {
    animation: ripple3 3s linear infinite;
  }
}

.landing {
  p {
    margin: 0 !important;
  }
  .container {
    max-width: 1700px;
  }
  .nav-link {
    margin-right: 1rem;
  }

  .center-x {
    margin: 0 auto;
  }
  .mnt-5 {
    margin-top: -5rem;
  }
  .mnt-4 {
    margin-top: -5rem;
  }
  .mnt-3 {
    margin-top: -3rem;
  }
  .fs-24 {
    font-size: 24px;
  }
  .fs-26 {
    font-size: 26px;
  }
  .fs-36 {
    font-size: 36px;
  }
  .fs-38 {
    font-size: 38px;
  }
  .navbar-light .navbar-toggler,
  .navbar-dark .navbar-toggler {
    border: none;
  }
  .navbar-toggler:focus {
    box-shadow: none !important;
  }
  .landing-border {
    height: 1px;
    background-color: $black;
  }

  .landing-shadow {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }

  .landing-input {
    border: none;
    min-height: 50px;
    border-radius: 30px;
    padding: 0 1.5rem;
  }

  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px !important;
    border-color: transparent !important;
    min-height: 50px;
    flex: 1;
  }

  .ff-bold {
    font-family: $font-family-folito-bold !important;
  }
  .ff-black {
    font-family: $font-family-folito-black !important;
  }
  .ff-medium {
    font-family: $font-family-folito-medium !important;
  }

  .onboarding-image {
    text-align: center;
    img {
      width: 100%;
    }
  }

  .switch {
    padding: 5px;

    .selected {
      background-color: $lightGrey;
      position: relative;
    }

    &-item {
      cursor: pointer;
      border: 1px solid;
      border-radius: 15px;
      padding: 2px 25px;
      flex: 1;
      text-align: center;
    }
    &-salon {
      margin-left: -1.4rem;
      color: $lightGrey;
    }
  }
  .navbar-collapse.show {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    top: 3.5rem;
    // left: 0;
    bottom: 0;
    height: 100vh;
  }
  &-client {
    .custom-navbar {
      min-width: 60vw;
    }
    .navbar-collapse.show {
      background-color: $lightGrey !important;
    }
    .switch-item {
      border-color: $secondary !important;
      color: $secondary !important;
    }
    .light {
      .selected {
        background-color: $primary !important;
        position: relative;
      }
    }
    .dark {
      .selected {
        background-color: $lightGrey;
        position: relative;
      }
    }
  }

  &-salon {
    .custom-navbar {
      min-width: 60vw;
    }
    .navbar-collapse.show {
      background-color: $lightGrey !important;
      .nav-link {
        color: $secondary;
      }
    }
    .light {
      .switch-item {
        border-color: $secondary !important;
        color: $secondary !important;
      }
      .selected {
        background-color: $secondary !important;
        color: $lightGrey !important;
        position: relative;
      }
    }
    .dark {
      .switch-item {
        border-color: $lightGrey !important;
        color: $lightGrey;
      }
      .selected {
        background-color: $lightGrey;
        color: $secondary !important;
        position: relative;
      }
    }
  }

  .about {
    .wave-svg {
      display: none;
    }
    &-wave {
      display: none;
      position: absolute;
      z-index: 0;
      top: 0;
      width: 100%;
    }
    &-image {
      // height: 100%;
    }
  }
  .service-title {
    font-size: 8px;
  }
  .ripple-section {
    .reasons {
      .ripple-image {
        display: none;
      }
      //   .splash-screenshot {
      //     max-width: 360px;
      //   }
      &-box {
        color: $lightGrey;
        height: 100%;
        // display: flex;
        // align-items: center;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;
          li {
            font-family: $font-family-folito-bold !important;
            position: relative;
            padding: 2rem 0rem 1rem 2rem;
          }
        }
      }
      &-timeline {
        border-left: 1px solid $gray-400;
        // border-right: 1px solid $gray-400;
        height: 100%;
        // padding: 5rem 0;
        li:before {
          content: "";
          background: $gray-700;
          position: absolute;
          height: 20px;
          width: 20px;
          left: -10px;
          top: 50%;
          border-radius: 50%;
          z-index: 2;
          border: 5px solid hsla(0, 0%, 100%, 0.9);
        }
      }
      &-timeline-right {
        border-left: 1px solid $gray-400;
        height: 100%;
        // padding: 5rem 0;
        li:before {
          content: "";
          background: $gray-700;
          position: absolute;
          height: 20px;
          width: 20px;
          left: -10px;
          top: 50%;
          border-radius: 50%;
          z-index: 2;
          border: 5px solid hsla(0, 0%, 100%, 0.9);
        }
      }
    }
  }
  .whatFor {
    .custom-card-bg {
      background-color: $secondary;
      box-shadow: 3px 3px 5px 6px #dedee0;
      border-radius: 30px;
      height: 250px;
      max-width: 350px;
      margin: 0 auto;
      padding: 10px 0;
    }
    .card-icon {
      height: 100%;
      max-height: 150px;
      img {
        height: 100%;
        max-height: 120px;
      }
    }
    .card-text {
      max-width: 180px;
      margin: 0 auto;
      font-size: 24px;
      color: $white;
      //   font-size: larger;
    }
  }

  .third-section,
  .raport-section {
    .wave-image {
      display: none;
      position: absolute;
      z-index: 0;
      top: 0;
      width: 100%;
    }
  }
  .raport-section {
    .wave-2 {
      display: none;
    }
  }
  .why {
    .rotated-image-wrapper {
      // height: 20vw;
      // max-height: 30vw;
      display: none;
    }
    // .rotated-image {
    // }
  }
  .features-section {
    .two-photos {
      text-align: center;
      img {
        width: 70%;
      }
    }
  }
  .faq-section {
    // min-height: 85vh;
  }
  .accordion-button:not(.collapsed) {
    color: $black !important;
    background-color: $lightGrey !important;
    // padding: 10px;
  }

  .accordion-button::after {
    background-image: none !important;
    display: none;
  }
  .accordion-collapse,
  .accordion-button {
    border: none;
    padding-left: 0;
  }

  .accordion-item:first-of-type .accordion-button {
    border: none;
  }

  .accordion-button:focus {
    border: none;
    box-shadow: none;
  }
  .accordion-body {
    padding: 0 1rem 1rem 1rem;
  }
  .loyalty-section {
    .loyalty-screenshot {
      width: 70%;
    }
  }
  .footer {
    color: $white;
    &-list ul li {
      display: block;
      margin-bottom: 15px;
      font-family: "FolitoBold";
      a {
        color: $white;
      }
    }
    .apple-btn {
      margin-left: 0 !important;
      margin-top: 1rem;
    }
  }

  .features-admin-section {
    .images-column {
      margin: auto 0;
    }
    .features-image-wrapper {
      img {
        max-width: 33.333%;
      }
      .left {
        transform: scale(0.9);
        position: relative;
        right: -10rem;
      }
      .middle {
        position: relative;
        z-index: 1;
      }
      .right {
        transform: scale(0.9);
        position: relative;
        left: -10rem;
      }
    }
    .features2-image-wrapper {
      img {
        max-width: 50%;
      }
      .left {
        position: relative;
        right: -2rem;
        z-index: 1;
      }
      .right {
        transform: scale(0.9);
        position: relative;
        left: -2rem;
      }
    }
  }
  .packages {
    .package {
      background-color: $lightGrey;
      border-radius: 40px;
      padding: 2rem 1rem;
      margin: 0 7px 0 7px;
      ul {
        // min-height: 55vh;
        min-height: 30rem;
        // max-height: 470px;
        padding: 2rem 1.5rem;
        li {
          margin-bottom: 5px;
        }
      }
    }
    .contact-button {
      // margin-top: 2rem;
      background-color: $primary;
      min-height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 60px;
    }
  }
}

@include media-breakpoint-up(md) {
  .landing {
    &-client,
    &-salon {
      .custom-navbar {
        min-width: auto;
      }
    }
    .ripple-section {
      .reasons {
        .ripple-image {
          display: flex;
          align-items: center;
        }
        &-box {
          ul {
            li {
              margin: 0 2rem;
            }
          }
        }
        &-timeline {
          border-left: none;
          border-right: 1px solid $gray-400;
          height: 100%;
          padding: 5rem 0;
          li:before {
            right: -41px;
            left: auto;
          }
        }
        &-timeline-right {
          border-left: 1px solid $gray-400;
          height: 100%;
          padding: 5rem 0;
          li:before {
            left: -41px;
          }
        }
      }
    }
    .mxw-md-440 {
      max-width: 440px !important;
    }

    .service-title {
      font-size: 12px;
    }
    .about {
      background-color: $lightGrey !important;
      .wave-svg {
        display: block;
      }
      &-wave {
        display: block;
        top: -16em;
      }
      &-screenshot {
        // width: 50%;
      }
    }
    .third-section {
      .wave-image {
        display: initial;
      }
      .image-wrapper {
        min-height: 90%;
        justify-content: flex-end;
        align-items: center;
        img {
          width: 70%;
          position: relative;
        }
      }
    }
    .raport-section {
      // .raport-section {
      // }
      // .wave-2 {
      //   display: initial;
      // }
      .image-wrapper {
        // min-height: 90%;
        justify-content: center;
        align-items: center;
        img {
          width: 65%;
          position: relative;
        }
      }
    }
    .onboarding-image {
      text-align: center;
      img {
        width: 70%;
      }
    }
    .faq-section {
      // min-height: 55vw;
    }
    .features-admin-section {
      .features-image-wrapper {
        .left {
          right: -7rem;
        }
        .right {
          left: -7rem;
        }
      }
    }
    .packages {
      .package {
        border-radius: 60px;
        padding: 3rem 2rem;
        margin: 0 1rem;
        ul {
          padding: 0.5rem;
          min-height: 25rem;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .alice-carousel__dots {
    display: none;
  }
  .landing {
    // &-client, &-salon {
    // 	.custom-navbar {
    // 		min-width: auto;
    // 	}
    // }
    .mnt-lg-0 {
      margin-top: 0;
    }
    .h1-lg {
      font-size: 2.5rem;
    }
    .service-title {
      font-size: 16px;
    }
    .fs-lg-24 {
      font-size: 24px;
    }
    .onboarding-image {
      text-align: left;
    }
    .features-section {
      .two-photos {
        text-align: left;
      }
    }
    .third-section {
      .image-wrapper {
        // min-height: 50vh;
        img {
          top: 4rem;
        }
      }
    }
    .raport-section {
      h2,
      p {
        color: $lightGrey !important;
      }
      .wave-2 {
        display: initial;
      }
      .image-wrapper {
        img {
          top: -8rem;
        }
      }
    }
    .why {
      .rotated-image-wrapper {
        display: block;
      }
      .rotated-image {
        position: absolute;
        z-index: 1;
        width: 80%;
        top: -5vw;
      }
    }
    .loyalty-section {
      .screenshot-container {
        // height: auto;
        position: absolute;
        top: -12rem;
      }
      .loyalty-screenshot {
        // position: absolute;
        width: 90%;
        // height: auto;
        // z-index: 1;
        // left: 0;
        // top: -9vw;
        // width: 70%;
      }
    }
    .packages {
      .package {
        ul {
          padding: 2rem;
          min-height: 23rem;
        }
      }
    }
    .faq-section {
      // min-height: 45vw;
    }
  }
}

@include media-breakpoint-up(xl) {
  .landing {
    .h1-xl {
      font-size: 2.5rem;
    }
    .landing-fmh-xl {
      min-height: calc(100vh - 75px) !important;
    }
    .service-title {
      font-size: 18px;
    }
    .about {
      &-wave {
        top: -2em;
      }
      &-screenshot {
        // height: 100%;
      }
      &-image {
        // height: calc(100vh - 75px);
        // width: 25vw;
        // max-height: 100%;
      }
    }
    &-salon {
      .about {
        &-image {
          // height: calc(100vh - 75px);
          width: 50%;
          max-height: 90vh;
          margin: 0 auto;
        }
      }
    }
    .third-section {
      .image-wrapper {
        // min-height: 77vh;
        img {
          top: 4rem;
        }
      }
    }
    .why {
      .rotated-image {
        top: -8rem;
      }
    }
    .loyalty-section {
      .loyalty-screenshot {
        // left: 0;
        // top: -15vw;
      }
    }
    .ripple-section {
      .reasons {
        .splash-screenshot {
          max-width: 55%;
        }
      }
    }
    .loyalty-section {
      .screenshot-container {
        top: -17rem;
      }
      .loyalty-screenshot {
        width: 70%;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .landing {
    .h1-xxl {
      font-size: 2.5rem;
    }
    .about {
      &-wave {
        top: -9em;
      }
    }
    .third-section {
      .wave-image {
        top: -6em;
      }
      .image-wrapper {
        // min-height: 77vh;
      }
    }
    .description-1 {
      padding: 0 10rem;
    }
    .loyalty-screenshot {
      // left: 7rem;
      // top: -34rem;
    }
  }
}
